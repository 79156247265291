import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();
export const rtdb = firebase.database();
export const auth = firebase.auth();

export const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export const currTime = firebase.firestore.Timestamp.fromDate(new Date());
export const sessionPersist = firebase.auth.Auth.Persistence.SESSION;
export const increment = firebase.database.ServerValue.increment(1);
export const decrement = firebase.database.ServerValue.increment(-1);

export const arrayUnion = (object: any) => {
  return firebase.firestore.FieldValue.arrayUnion(object);
};
